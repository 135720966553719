import React, { useEffect } from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import "./style/layout.css"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from 'react-redux'
import { setBlog, setProducts, setCategories, setProductType } from "../state/app"
import { useTranslation } from "react-i18next"
import { useI18next } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import HeaderMobile from "./Header/HeaderMobile"



const Layout = ({ children, dispatch }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()

  const { language } = useI18next()
  const data = useStaticQuery(graphql`
    query layout ($language: String){
        allWpPost(sort: { fields: [date], order: DESC }) {
            nodes {
                uri
                date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
                title
                content
                excerpt
                isSticky
                categories {
                    nodes {
                    name
                    }
                }
                tags {
                    nodes {
                        slug
                    }
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    layout: FULL_WIDTH
                                    formats: [AUTO, WEBP, JPG]
                                    quality: 100
                                )
                            }
                        }
                    }
                }
            }
        }

        allWpProduct (filter: {productTags: {nodes: {elemMatch: {slug: {eq: $language }}}}}) {
          nodes {
            name
            description
            shortDescription
            id
            featured
            productCategories {
              nodes {
                slug
                name
              }
            }
            link
            galleryImages {
              nodes {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            attributes {
              nodes {
                name
                options
              }
            }
            productTags {
              nodes {
                name
                slug
                id
              }
            }
          }
        } 

        allWpProductTag {
          nodes {
            name
            slug
          }
        }

        allWpProductCategory {
            nodes {
              link
              name
              slug
              databaseId
              parentDatabaseId
            }
        }
    }
  `, {
    language: language,
  }
  )

  useEffect(() => {
    dispatch(setBlog(data?.allWpPost?.nodes))
    dispatch(setProducts(data?.allWpProduct?.nodes))
    dispatch(setProductType(data?.allWpProductTag?.nodes))
    dispatch(setCategories(data?.allWpProductCategory?.nodes))
  })

  return (
    <>
      {breakpoints.m ?
        <HeaderMobile
          categories={data?.allWpProductCategory?.nodes}
          tag={data?.allWpProductTag?.nodes}
          data={t("home", { returnObjects: true })}
          text={t("products", { returnObjects: true })}
        />
        :
        <Header
          categories={data?.allWpProductCategory?.nodes}
          tag={data?.allWpProductTag?.nodes}
          data={t("home", { returnObjects: true })}
          text={t("products", { returnObjects: true })}
        />
      }
      <main>{children}</main>
      <Footer
        data={t("home", { returnObjects: true })}
      />
    </>
  )
}

export default connect(state => ({
  blog: state.app.blog,
  products: state.app.products,
  productType: state.app.productType,
  categories: state.app.categories,
  cart: state.app.cart,
}), null)(Layout)
