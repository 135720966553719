import React, { useState, useEffect } from "react"
import TopBar from './TopBar'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { color, media } from '../Styles'
import { Image } from '../Images'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { connect } from 'react-redux'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { setCart } from "../../state/app"
import Button2 from "../Button"
import Searchbar from "../SearchBar"
import Headroom from "react-headroom"
import SearchData from "../../../locales/pt/search.json"

//SVG
import Logo from '../../images/logo.svg'
import LogoWhite from '../../images/logo-white.svg'
import CloseIcon from '../../images/header-footer/close.svg'
import CartsIcon from '../../images/header-footer/cart2.svg'
import FormIcon from '../../images/header-footer/form.svg'
import MenuIcons from '../../images/header-footer/menu.svg'
import SearchIcon from '../../images/header-footer/search.svg'


//MATERIAL UI
import Badge from "@material-ui/core/Badge";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Fab } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import ClickAwayListener from '@mui/base/ClickAwayListener';
import IconButton from "@material-ui/core/IconButton";
import { Offcanvas } from "react-bootstrap"
import { useTranslation } from "react-i18next"


const Header = ({ cart, dispatch, data, text }) => {
    const breakpoints = useBreakpoint()
    const { t } = useTranslation()

    // SEARCH
    const [searchOpen, setSearchOpen] = React.useState(false);
    const handleSearchToggle = () => {
        setSearchOpen(!searchOpen);
    };

    // MENU
    const [open, setOpen] = useState(false);

    // ADD PRODUCT 
    const [itemCount, setItemCount] = React.useState(1);
    const [showCartMenu, setShowCartMenu] = useState(false)

    // REMOVE PRODUCT FROM CART
    const [updateCart, setUpdateCart] = useState(cart);

    useEffect(() => {
        dispatch(setCart(updateCart))
    }, [updateCart])

    const colors = ['#000', '#000', '#F2AF00'];


    // ---

    return (
        <header>
            <TopBar data={t("home", { returnObjects: true })} />
            <HeaderStyled>
                <Headroom style={{
                    webkitTransition: 'all .5s ease-in-out',
                    mozTransition: 'all .5s ease-in-out',
                    oTransition: 'all .5s ease-in-out',
                    transition: 'all .5s ease-in-out',
                    backgroundColor: "white",
                    zIndex: "2"
                }}>
                    <div className='wrapper'>
                        <div className="left">
                            <Link to="/">
                                <Logo className='logo' />
                            </Link>
                            <div className="navbar">
                                {data.header.navbar.map((item, key) => {
                                    return (
                                        <Link to={item.link} key={key} style={{ color: colors[key] }} className="topmenu">
                                            {item.title}
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                        <section className='right'>
                            <div className='search'>
                                <IconButton
                                    onClick={handleSearchToggle}
                                    // onClick={handleClick}
                                    aria-label="pesquisar"
                                    style={{
                                        color: "black",
                                        backgroundColor: "transparent",
                                        padding: "12px 0"
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                {searchOpen && (
                                    <ClickAwayListener onClickAway={handleSearchToggle}>
                                        <Searchbar
                                            data={SearchData}
                                        />
                                    </ClickAwayListener>
                                )}
                            </div>

                            <div className='form' onClick={() => setShowCartMenu(!showCartMenu)}>
                                <Badge color="secondary" badgeContent={cart.length}>
                                    <FormIcon />
                                </Badge>
                                <span class="tooltiptext">Orçamento</span>
                            </div>

                            <div className='cart'>
                                <Link to="/manutencao">
                                    <CartsIcon />
                                </Link>
                                <span class="tooltiptext">Loja Online</span>
                            </div>


                            <div className='menu'>

                                <MenuIcons onClick={() => setOpen(true)} />
                                <span class="tooltiptext">Menu</span>
                                <SwipeableDrawer
                                    anchor="left"
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                >
                                    <div
                                        style={{ width: "100vw" }}
                                    >


                                        <MenuStyled>
                                            <div className='wrapper'>
                                                <Link to='/'><Image src='header-footer/bg.png' alt='Enomac' /></Link>
                                                <div className='top'>
                                                    <LogoWhite className='logo' />
                                                    <CloseIcon
                                                        onClick={() => setOpen(false)}
                                                        onKeyPress={() => setOpen(false)}
                                                        role="button"
                                                        tabIndex={0}
                                                        className='close'
                                                    />
                                                </div>

                                                <nav class="menu">
                                                    <ul>
                                                        <li><a href="/about">{data.header.menu2.about.title}</a></li>
                                                        <li class="dropdown"><a href="#">{data.header.menu2.machinery.title}</a>
                                                            <ul style={{ textTransform: "lowercase" }} className="link-container">
                                                                <li><a>{data.header.menu2.machinery.submenu[0]}</a></li>
                                                                <li><a>{data.header.menu2.machinery.submenu[1]}</a></li>
                                                                <li><a href="/categoria-produto/enchimento">{data.header.menu2.machinery.submenu[2]}</a></li>
                                                                <li><a href="/categoria-produto/visao-artificial">{data.header.menu2.machinery.submenu[3]}</a></li>
                                                            </ul>
                                                        </li>
                                                        <li><a href="/services">{data.header.menu2.services.title}</a></li>
                                                        <li><a href="/manutencao">{data.header.menu2.online.title}</a></li>
                                                        <li><a href="/manutencao">{data.header.menu2.news.title}</a></li>
                                                        <li><a href="/contacts">{data.header.menu2.contact.title}</a></li>
                                                    </ul>
                                                </nav>

                                                {!breakpoints.l &&
                                                    <div className='bottom'>
                                                        <div className='politics'>
                                                            <a href='/privacy'>{data.footer.privacy}</a>
                                                            <a href='/cookies'>{data.footer.cookies}</a>
                                                        </div>
                                                        <div className='contacts'>
                                                            <a href="mailto:geral@enomac.pt">geral@enomac.pt</a>
                                                            <a href='+351234726253'>+351 234 726 253</a>
                                                        </div>
                                                        <div className='address'>
                                                            <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Av. da Estação, LT. 2, Fração C</a>
                                                            <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Z. Ind. Oiã, 3770-059 Oiã</a>
                                                            <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Aveiro, Portugal</a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </MenuStyled >
                                    </div>
                                </SwipeableDrawer>
                            </div>
                        </section>
                        <Offcanvas placement="end" show={showCartMenu} >

                            <Offcanvas.Body>
                                <CartMenuStyled>
                                    <Offcanvas.Header>
                                        <>
                                            {cart.length >= 1 &&
                                                <div className="newlayout">
                                                    <div className="campo">
                                                        <h4 className="text">{text.button.requests.title}</h4>
                                                        <div className="icone">
                                                            <h6 className="close-text">{text.button.close.title}</h6>
                                                            <CloseIcon
                                                                className="close-cart"
                                                                onClick={() => setShowCartMenu(!showCartMenu)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    </Offcanvas.Header>
                                    {cart.length === 0 &&
                                        <div className="cartLength">
                                            <div className="cart-btn">
                                                <CloseIcon
                                                    className="close-cart"
                                                    onClick={() => setShowCartMenu(!showCartMenu)}
                                                />
                                            </div>

                                            <h4>
                                                {data.header.menu.budget.text}
                                                <br></br>
                                                {data.header.menu.budget.text2}
                                            </h4>

                                            <div className="cart-secBtn">
                                                <Button2
                                                    text={data.header.menu.budget.option1}
                                                    to={"/products"}
                                                />
                                                <Button2
                                                    text={data.header.menu.budget.option2}
                                                    to={"/services"}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {/* CART COM PRODUTOS */}
                                    {cart.map((product, i) => {
                                        const image = {
                                            image: getImage(product.image?.localFile),
                                            alt: product.image?.altText || '',
                                        }
                                        return (
                                            <div className='product' key={i}>
                                                {image?.image &&
                                                    <GatsbyImage image={image.image} alt={image.alt || ''} className='product-image' />
                                                }
                                                <div className='product-info'>
                                                    <h3>{product.name}</h3>
                                                    <span className='product-quantity'>
                                                        {text.button.quantity.title}
                                                        <b>{product.quantity}</b>
                                                        <br></br><br></br>

                                                        <div className="product-btn">
                                                            <div className="product-decrement">
                                                                <Fab size="small" onClick={() => {
                                                                    cart.find(addedProduct => {
                                                                        if (addedProduct.id === product.id) {
                                                                            addedProduct.quantity = addedProduct.quantity - 1
                                                                        }
                                                                    })
                                                                    setUpdateCart([...cart])

                                                                    if (product.quantity <= 0) {
                                                                        setUpdateCart(updateCart.filter(deleteProduct => deleteProduct.id !== product.id))
                                                                    }
                                                                }}><RemoveIcon fontSize="small" /></Fab>
                                                            </div>
                                                            <div className="product-increment">
                                                                <Fab size="small" onClick={() => {
                                                                    if (product.quantity >= 1) {
                                                                        cart.find(addedProduct => {
                                                                            if (addedProduct.id === product.id) {
                                                                                addedProduct.quantity = addedProduct.quantity + 1
                                                                            }
                                                                        })
                                                                        setUpdateCart([...cart])
                                                                    }
                                                                }}><AddIcon fontSize="small" /></Fab>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <CloseIcon
                                                    className='remove-product'
                                                    onClick={() => setUpdateCart(updateCart.filter(deleteProduct => deleteProduct.id !== product.id))}
                                                />
                                            </div>
                                        )
                                    })}
                                    {cart.length !== 0 &&
                                        <div className='bottom-check'>
                                            <button className="box" ><a href="/pedido">{text.button.send.title}</a></button>
                                            <button className="box2" ><a href="/services">{text.button.consult.title}</a></button>
                                            <button className="box3" ><a href="/products">{text.button.consultMach.title}</a></button>
                                        </div>
                                    }
                                </CartMenuStyled>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </Headroom>
            </HeaderStyled>


        </header >
    )
}


export default connect(state => ({
    cart: state.app.cart
}), null)(Header)

const CartMenuStyled = styled.div`

.newlayout{
    .campo{
        display: grid;
        grid-template-columns: auto 1fr;
        border-bottom: 1px solid #000;
        gap: 41%;
        padding: 22px 5%;
        gap: 39%;
        padding-right: 6%;
        align-items: center;

        ${media.s`
            padding: 6.4% 5%;
        `}

        @media only screen and (max-width: 1580px){
            gap:40%;
            gap:38%;
        }
        .text{
            letter-spacing: 2px; 
            font-weight: 600;
            text-transform: uppercase;
            font-size: 8px;
        }
        .icone {
            display: flex;
            margin-top: 12px;
            gap: 2%;
            justify-content: space-between;

            .close-text{
                padding-right: 10px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .close-cart {
                padding: 9px;
                border-radius: 50%;
                background-color: #fff;
            }
        }
    }
}

.cartLength {
    .cart-btn {
        margin: 8px;

        .close-cart{
            width:25px;
            height:25px;
            cursor: pointer;
        }
    }

    h4{
        text-align: center;
        margin-top: 1rem;
        font-family: Montserrat;
        text-transform: uppercase;
    }

    .cart-secBtn {
        display: flex;
        gap: 1rem;
        text-align: center;
        justify-content: center;
    }

}
    
.product-btn {
    display: flex;
}

.product-decrement, .product-increment {
    padding: 5px;
}


    .product {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:5%;
        padding: 5%;
        border-bottom: 1px solid ${color.gray};
        max-width: 360px

        .product-info {
            .product-quantity {
                
            }
        }

        .remove-product {
            width: 20%;
            margin-left: 80%;
        }
    }


    .bottom-check{
        padding: 1rem 5%;
        background-color:#ebebeb;
        position: sticky;
        bottom: 0;

        .box {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px, 5vw, 35px) ;
            background-color: #F2AF00 ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat";
            }

            :hover {
                background-color: #000;
                color: #F2AF00;
            }
        }
        .box2 {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px, 5vw, 35px) ;
            background-color: #8080806e ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat"
            }

            :hover {
                background-color: #000;
                color: #F2AF00;
            }
        }
        .box3 {
            width:100%;
            margin-bottom: 5%;
            padding: 1.5rem clamp(15px,5vw,35px) ;
            background-color: #8080806e ;
            border: none;

            a{
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2%;
                font-family: "Montserrat";
            }

            :hover {
                background-color: #000;
                color: #F2AF00;
            }
        }
    }


`


const HeaderStyled = styled.div`
    border-bottom:2px solid ${color.yellow};
    background: #fff;

    .wrapper{
        position:relative;
        max-width:2560px;
        box-sizing:border-box;
        margin:0 auto;
        padding:1.5rem 5%;
        display:flex;
        justify-content:space-between;
        align-items:center;

        ${media.m`
            padding:1rem 1%;
        `}

        ${media.s`
            padding:1rem 5%;
        `}

        .left{
            display:flex;
            max-width:550px;
            gap: 6%;
            align-items: center;
            

            ${media.m`
                max-width:120px;
            `}

            .navbar {
                display: flex;
                width: 100%;
                gap: 1rem;
            }

            .logo{
                width:80%;

                ${media.m`
                    width:90%;
                `}
            }

            .topshop{
                width: 110px;
                color:${color.yellow};
                font-weight: 600;
            }

            .topmenu {
                font-weight: 500;
            }

            a {
                text-transform: uppercase;
                font-family: "Montserrat";
            }
        }

        .right{
            display:flex;
            align-items:center;
            gap:1.5rem;
            flex-wrap: wrap;

            ${media.m`
                gap:1rem;
            `}

            svg{
                height:20px;

                ${media.m`
                    height:20px;
                `}
            }

            .search{
                
                svg{
                    height:20px;
                    ${media.m`
                        height:20px;
                    `}
                } 
            }

            .home {
                position: relative;
                display: inline-block;

                .MuiBadge-anchorOriginTopRightrectangular {
                    top: -15px;
                }

                :hover .tooltiptext {
                    visibility: visible;
                  }

                .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    left: 50%;
                    margin-left: -60px;
                }

                svg{
                    height:22px;
                    cursor: pointer;
                    ${media.m`
                        height:20px;
                    `}
                }
            }

            .cart{
                position: relative;
                display: inline-block;

                :hover .tooltiptext {
                    visibility: visible;
                  }

                .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    left: 50%;
                    margin-left: -60px;
                }

                svg{
                    height:25px;
                    cursor: pointer;

                    ${media.m`
                        height:20px;
                    `}
                } 
                
                .quantity{
                    position: absolute;
                    bottom: 0px;
                    left: -10px;
                    color: #FFF;
                    background: #F2AF00;
                    border: 0;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 0.9rem;
                    text-align: center;
                    padding: 1%;
                }
            }

            .form {
                position: relative;
                display: inline-block;

                :hover .tooltiptext {
                    visibility: visible;
                  }

                .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    left: 50%;
                    margin-left: -60px;
                }

                svg{
                    height:25px;
                    cursor: pointer;

                    ${media.m`
                        height:20px;
                    `}
                }
            }

            .menu {
                position: relative;
                display: inline-block;

                :hover .tooltiptext {
                    visibility: visible;
                  }

                .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                    
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 1;
                    top: 100%;
                    left: 50%;
                    margin-left: -60px;
                }

                .icon-menu{
                    cursor:pointer;
                }
            }
        }
    }
`

const MenuStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index:2;

    .wrapper{
        max-width:2560px;
        box-sizing: border-box;
        margin:0 auto;
        padding:5vh 5%;
        height: 100%;

        

        .image{
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            z-index:-1;
        }

        .top{
            display:flex;
            margin-bottom:4rem;
            gap:85%;

            @media only screen and (max-height:900px), only screen and (max-width: 700px){
                justify-content:space-between;
                margin-bottom:3rem;
                gap:50%;
            }
                
            .logo{
                width:180px;
                cursor:"pointer"
                

                ${media.m`
                    width:100px;
                `}
            }

            .close{
                cursor:pointer;
                width:35px;
                height:35px;

                ${media.m`
                    width:20px;
                    height:20px;
                `}
            }
        }

        *{
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

        nav {
            width: 250px;
            position: absolute;
            top: 117px;
            bottom: 0;
          }
          
          nav ul {
            position: relative;
            list-style-type: none;
          }
          
          
          nav ul li a {
            display: flex;
            align-items: center;
            font-size: 1.10em;
            text-decoration: none;
            text-transform: uppercase;
            color: #fff;
            height: 50px;
            transition: .5s ease;
            border-bottom: 2px solid ${color.yellow};
            max-width: 180px;
            font-weight: 500;
            letter-spacing: 0.1rem;

            ${media.m`
                font-size:1rem;
                max-width: 150px;
            `}

            
          }

          nav ul li ul li a {
            text-transform: lowercase;
          }

          nav ul li ul li:first-child {
            :before{
                content:'';
                position:absolute;
                left: -20%;
                height: 10px;
                width: 10px;
                margin-top: 10%;
                background-color: ${color.yellow};
                border-radius: 50%;
                display: inline-block;

                ${media.m`
                    left: -15%;
                `}
            }
          }
          

          nav ul li a:hover {
            color: ${color.yellow};
          }
          
          nav ul ul {
            position: absolute;
            left: 250px;
            width: 200px;
            top: 0;
            display: none;
            border-radius: 5px;

            ${media.s`
                left: 200px;
            `}
          }
          
          nav ul span {
            position: absolute;
            right: 20px;
            font-size: 4.5em;
            color: transparent;
          }
        
          nav ul span:hover {
            color: #F2AF00;
          }
        
        
          
          nav ul .dropdown {
            position: relative;
          }
          
          nav ul .dropdown:hover > ul {
            display: initial;
          }
          
          nav ul .dropdown_two ul {
            position: absolute;
            left: 200px;
            top: 0;
          }
          
          nav ul .dropdown_two:hover ul {
            display: initial;
          }
          
          nav ul .split ul {
            top: 250px;
          }

          .bottom{
            position:absolute;
            bottom:0;
            right:0;
            padding: 0 5% 5vh 0;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .politics, .contacts, .address{
                display:flex;
                flex-direction:column;
                gap:0.5rem;
            }
            a{
                text-transform:uppercase;
                color:${color.lightGrey};
                letter-spacing:0.1rem;
                font-size:0.9rem;
            }
        }
    }
`