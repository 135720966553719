import * as React from "react"
import styled from 'styled-components'
import { color } from '../Styles'
import { Image } from '../Images'
import Bottom from './Bottom'
import Top from './Top'

import { useTranslation } from "react-i18next"


const Footer = () => {
    const { t } = useTranslation()
    return (

        <>
            <FooterStyled>
                <Image src='header-footer/bg.png' alt='Enomac' />
                <div className='wrapperr'>
                    <Top data={t("home", { returnObjects: true })} />
                    <Bottom data={t("home", { returnObjects: true })}/>
                </div>
            </FooterStyled>
        </>
    )
}

export default Footer

const FooterStyled = styled.div`
    position:relative;

    .image{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        z-index:-1;   
    }

    .wrapperr{
        max-width:2560px;
        margin:0 auto;
        padding:clamp(30px,10vw,90px) 5%;
        color:${color.white};
        letter-spacing:0.1rem;
        font-size:0.9rem;

        a{
            color:${color.white};
        }

        hr{
            border:1px solid ${color.yellow};
            margin-bottom:1.5rem;
            margin-top:1.5rem;
        }
    }
`