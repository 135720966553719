

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Cookies({ data }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {data.footer.cookies}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Politica de cookies</b></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>
                    A ENOMAC, Lda. utiliza cookies que permitem melhorar o desempenho e a experiência de navegação do utilizador. Qualquer navegador de internet (browser) permite ao utilizador aceitar, recusar ou apagar cookies, através das definições do próprio navegador.
                    Se optar por desativar os cookies, alguns serviços do nosso site irão deixar de funcionar, afetando assim a sua experiência de navegação no site.<br /><br />

                    <b>O que são cookies?</b><br /><br />
                    Os cookies são curtos ficheiros de texto com informações relevantes que o seu browser processa, quando um site é visitado por um utilizador.<br /><br />
                    A colocação de cookies ajuda o Sítio a reconhecer o dispositivo (tablet, desktop, mobile, etc) do utilizador sempre que nos visita. Os cookies retêm apenas informação relacionada com as suas preferências, não revelando informações pessoais.<br /><br />
                    Para que a sua experiência de navegação seja otimizada o mais possível, recomendamos que não desative os cookies. Os mesmos aumentam a rapidez de resposta na navegação.<br /><br />

                    <b>Tipos de cookies:</b><br /><br />
                    Existem vários tipos de cookies com caraterísticas e funções diferentes. São eles:
                    • Cookies essenciais – São bastante importantes para aceder a áreas específicas do site permitindo uma navegação correta.<br /><br />
                    • Cookies de funcionalidade – Os cookies de funcionalidade permitem que as preferências do utilizador sejam guardadas quando visita o site. Desta forma, não é necessário personalizar o Sítio sempre que o visita.<br /><br />
                    • Cookies analíticos – Este tipo de cookies permite analisar a forma como os utilizadores utilizam o Sítio, conhecer as páginas mais populares e monitorizar o desempenho do site, possibilitando perceber possíveis mensagens de erro. Nunca serão reveladas informações de cariz pessoal.<br /><br />

                    <b>Considerações Finais</b><br /><br />
                    A ENOMAC, Lda. reserva-se o direito de alterar as normas descritas nesta página pelo que aconselhamos a visita frequente a este endereço. Consideramos que os utilizadores estão conscientes destas políticas ao navegar em www.enomac.pt.
                </p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Cookies