import React from "react"
import styled from 'styled-components'
import { color, media } from '../Styles'


//SVG
import Logo from '../../images/livro_reclamacoes.svg'


import Privacy from "../privacy"
import Cookies from "../Cookies"
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Bottom = () => {
    const { t } = useTranslation()

    return (
        <header>
            <BottomStyled>
                <div className='wrapper'>
                    <div className="left">

                        <p className="topmenu" style={{ width: "205px" }}><Privacy data={t("home", { returnObjects: true })} /></p>
                        <p className="topmenu" style={{ width: "170px" }}><Cookies data={t("home", { returnObjects: true })} /></p>

                        <a href="https://www.livroreclamacoes.pt/Inicio/">
                            <Logo className='logo' />
                        </a>
                    </div>
                    <section className='right'>
                        <div className='copyright'>

                            <span>Copyright 2022 | Enomac, Lda - Created:<a href='https://invisual.pt/'>Invisual.pt</a></span>

                        </div>

                    </section>
                </div>
            </BottomStyled>


        </header >
    )
}

export default Bottom


const BottomStyled = styled.div`
    border-top:2px solid ${color.yellow};
    background: transparent;
   
    .wrapper{
        position:relative;
        max-width:2560px;
        box-sizing:border-box;
        margin:0 auto;
        display:flex;
        justify-content:space-between;
        align-items:center;

        ${media.l`
            flex-direction:column;
            align-items: center;
            
        `}

        ${media.m`
            padding:1rem 1%;
            text-align: center;
        `}

        .left{
            display:flex;
            max-width:700px;
            gap: 4%;
            align-items: center;
            

            ${media.m`
                max-width:120px;
            `}

            ${media.s`
                max-width:120px;
                display:inline-table;
            `}

            .logo{
                width:60%;

                ${media.m`
                    width:50%;
                `}

            }

            .topshop{
                width: 170px;
                color:${color.yellow};
                font-weight: 600;

               
            }

            .topmenu {
                font-weight: 500;
                margin: 0 auto;
            }

            a {
                text-transform: uppercase;
                font-family: "Montserrat";
                color: white;
            }
        }

        .right{
            display:flex;
            align-items:center;
            gap:1.5rem;
            cursor:pointer;
            flex-wrap:wrap;

            .copyright{
                position:relative;

                span {
                    color: #fff;
                }
                
                svg{
                    height:20px;

                    ${media.m`
                        height:20px;
                    `}
                } 
            }

        }
    }
`



