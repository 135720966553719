import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media, color } from './Styles'

const Button2 = ({ to, text, center, data }) => {
    return(
        <>
            <ButtonStyled className='button' center={center}>
                <Link 
                    to={to ? to : '/about'} 
                    className='button'
                >
                    <span className='text-btn'>{text ? text : data.bottom.title[0]}</span>
                </Link>
            </ButtonStyled>
        </>
    )
}

export default Button2

const ButtonStyled = styled.div`
    .button{
        display: block;
        width:fit-content;
        text-decoration: none;
        color: ${color.white};
        background: ${color.yellow};
        margin-top: 10px;
        padding: 1rem clamp(15px, 5vw, 35px);
        letter-spacing: 1px;
        text-align: center;
        position: relative;
        transition: all .35s;
        cursor:pointer;
        font-weight:600;
        text-transform:uppercase;
        ${props => props.center && `margin: 0 auto;`}

        .text-btn{
            position: relative;
            white-space:nowrap;

            ${media.s`
                white-space: break-spaces;
            `}
        } 
        
        :hover{
            background-color: #000;
            color: ${color.yellow};
        }
    } 
`