import * as React from "react"
import styled from 'styled-components'
import { color, media } from '../Styles'

import Email from '../../images/header-footer/email.svg'
import Phone from '../../images/header-footer/phone.svg'
import Facebook from '../../images/header-footer/facebook.svg'
import Instagram from '../../images/header-footer/instagram.svg'
import Linkedin from '../../images/header-footer/linkedin.svg'
import Youtube from '../../images/header-footer/youtube.svg'

const Top = ({ data }) => (
    <TopStyled>
        <section className='left'>
            <div className='contacts'>
                <h2>{data.footer.title[0]}</h2>
                <hr />
                <div className='email-content'>

                    <h5>{data.footer.title[2]}</h5>
                </div>
                <div className='number-content'>
                    <Phone />
                    <a href='/'>+351 927 495 571</a>

                </div>
                <small>{data.footer.phonealt2}</small>
                <hr className="hr2"></hr>

                <div className='email-content'>

                    <Email />
                    <a href="mailto:geral@enomac.pt">geral@enomac.pt</a>
                </div>
                <div className='number-content'>
                    <Phone />
                    <a href='/'>+351 234 726 253</a>
                </div>
                <small >{data.footer.phonealt}</small>
                <div></div>
            </div>
            <div className='social'>
                <h2>{data.footer.title[1]}</h2>
                <hr />
                <div className='icons'>
                    <a href='https://www.facebook.com/Enomac.pt' target="_blank"><Facebook /></a>
                    <a href='https://www.instagram.com/enomacpt/' target="_blank"><Instagram /></a>
                    <a href='https://www.linkedin.com/company/enomacpt?trk=similar-pages' target="_blank"><Linkedin /></a>
                    <a href='https://www.youtube.com/channel/UClBuu7BbPuJl-SFDBtSs8PA' target="_blank"><Youtube /></a>
                </div>
            </div>
        </section>
        <section className='right'>
            <div className='address'>
                <h2>Enomac, LDA</h2>
                <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Av. da Estação, LT. 2, Fração C</a>
                <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Z. Ind. Oiã, 3770-059 Oiã</a>
                <a href='https://goo.gl/maps/V2PtvQtx4wHE87fd9'>Aveiro, Portugal</a>
            </div>
        </section>
    </TopStyled>
)


export default Top

const TopStyled = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom:clamp(30px,0vw,90px);
    gap:5rem;

    ${media.l`
        flex-direction:column;
        align-items:center;
    `}

    h2{
        text-transform:uppercase;
        font-weight:900;
        margin-bottom:1rem;
        letter-spacing: 0.2rem;
        font-size: 22px;
        color:${color.grey};

        ${media.l`
            text-align:center;
        `}
    }

    .left{
        display: flex;
        gap: 5rem;

        ${media.l`
            justify-content:center;
        `}

        ${media.s`
            flex-direction:column;
        `}

        .contacts{
            .email-content, .number-content{
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-bottom: -7px;
            }

            .email-content{
                margin-bottom:1rem;

                h5 {
                    width: 134px;
                    font-size: 15px; 
                    color: #FFFFFF4D; 
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            svg{
                width:20px;
            }

            hr {
                
            }

            small {
                color: #F2AF00;
                font-size: 9px;
                margin-left: 35px;
            }
            
        }

        .social{
            .icons{
                display: flex;
                gap: 1.5rem;
                align-items: center;

                ${media.l`
                    justify-content:center;
                `}

                a{
                    display:flex;

                    svg{
                        height:18px;
                        width:25px;
                    }
                }   
            }
        }
    }

    .right{
        text-align:right;

        ${media.l`
            text-align:center;
        `}

        h2{
            color:${color.white};
        }

        .address{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            a{
                color:${color.lightGrey};
            }

            h2 {
                margin-top: 0px;
            }
        }
    }
`