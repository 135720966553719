import * as React from "react"
import styled from 'styled-components'
import { color, media } from '../Styles'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Email from '../../images/header-footer/email.svg'
import Phone from '../../images/header-footer/phone.svg'
import Facebook from '../../images/header-footer/facebook.svg'
import Instagram from '../../images/header-footer/instagram.svg'
import Linkedin from '../../images/header-footer/linkedin.svg'
import Youtube from '../../images/header-footer/youtube.svg'

const TopBar = ({ data }) => {
    const { languages, originalPath } = useI18next()
    const breakpoints = useBreakpoint()

    return(
        <TopBarStyled>
            <nav>
                {!breakpoints.xl &&
                    <section className='left'>
                    </section>
                }
                <section className='right'>
                    
                    
                    <div className='lang'>
                        <div className='lang-content'>
                            {languages.map((lng, i) => (
                                <span key={i}>
                                    <Link to={originalPath} language={lng}>
                                        {lng}
                                    </Link>
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='orcamento'>
                        <Link to='/contacts' className='btn-orcamento'>{data.topbar.title}</Link>
                    </div>
                </section>
            </nav>
        </TopBarStyled>
    )
}


export default TopBar

const TopBarStyled = styled.div`
    background-color:${color.black};

    nav{
        max-width:2560px;
        box-sizing:border-box;
        margin:0 auto;
        padding:0 0;
        color:${color.white};
        display:flex;
        justify-content:space-between;
        align-items:center;
        letter-spacing:0.1rem;
        font-size:0.9rem;

        ${media.m`
            padding:0;
        `}

        a{
            color:${color.white};
        }

        .left{
            .social{
                a{
                    border-left:1px solid ${color.yellow};
                    padding:1rem 1rem;

                    :last-child{
                        border-right:1px solid ${color.yellow};
                    }

                    svg{
                        height:12px;
                        width:15px;
                    }
                }
            }
        }

        .right{
            display:flex;
            align-items:center;
            border-left:1px solid ${color.yellow};

            ${media.xl`
                margin-left:auto;
            `}

            ${media.m`
                margin-left:unset;
                justify-content:space-between;
                width:100%;
            `}

            svg{
                width:15px;
                margin-right:1rem;
            }

            .contact{
                padding:0 1.5rem;
            }

            .email, .lang, .orcamento{
                border-left:1px solid ${color.yellow};
                padding:1rem 1.5rem;

                ${media.m`
                    padding:1rem 1rem;
                `}
            }

            .contact-content{
                display:flex;
                align-items:center;
            }

            .email-content{
                display:flex;
                align-items:center;
            }
            
            .lang-content{
                display: flex;
                gap: 0.5rem;
                
                a{
                    text-transform:uppercase;
                    font-weight:600;
                }

                a[aria-current]{
                    color:${color.yellow};
                }
            }

            .orcamento{
                text-transform: uppercase;
                font-weight:600;
                background-color:${color.yellow};

                .btn-orcamento{
                    color:${color.black};
                }
            }
        }   
    }
`