import React, { useState } from "react";
import "../components/style/SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "gatsby";

function SearchBar({ products, categories }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = products.filter((products) => {
            return products.name.toLowerCase().includes(searchWord.toLowerCase());
        });
        const newFil = categories.filter((categories) => {
            return categories.name.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter, newFil);
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };


    return (
        <Paper>
            <div className="search">
                <div className="searchInputs">
                    <input
                        type="text"
                        placeholder="Pesquisar"
                        value={wordEntered}
                        onChange={handleFilter}
                    />
                    <div className="searchIcon">
                        {filteredData.length === 0 ? (
                            <SearchIcon />
                        ) : (
                            <CloseIcon id="clearBtn" onClick={clearInput} />
                        )}
                    </div>
                </div>
                {filteredData.length != 0 && (
                    <div className="dataResult">
                        {filteredData.map((products, index) => {
                            if (filteredData.slug !== 'uncategorized') {
                                return (
                                    <>
                                        <Link className="dataItem" to={`/products`+ products.link}>
                                            <p>{products.name} </p>
                                        </Link>
                                        
                                        <Link className="dataItem" to={categories[index].link}>
                                            <p>{categories[index].name} </p>
                                        </Link>
                                    </>
                                );
                            }
                        })}
                    </div>
                )}
            </div>
        </Paper>
    );
}

export default connect(state => ({
    products: state.app.products,
    categories: state.app.categories
}), null)(SearchBar)